jQuery(($) => {
  let dropdownSelector = document.querySelector('#categoryFilterDropdown')
  if (typeof dropdownSelector != 'undefined' && dropdownSelector != null) {
    dropdownSelector.onchange = onCatChange
  }
  function onCatChange() {
    if (dropdownSelector.options[dropdownSelector.selectedIndex].value > 0) {
      let newValue =
        dropdownSelector.options[dropdownSelector.selectedIndex].value
      location.href = `${location.origin}/?cat=${newValue}`
    }
  }
})
