import 'owl.carousel2'

const CAROUSEL_ARGS = {
  Slider: {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    items: 1,
    smartSpeed: 1000,
    navText: [
      '<span class="sr-only">Next Item</span>',
      '<span class="sr-only">Previous Item</span>'
    ],
    autoHeight: true
  },
  Cards: {
    loop: false,
    margin: 10,
    nav: false,
    items: 1
  },
  Testimonial: {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    items: 1,
    smartSpeed: 1000,
    navText: [
      '<span class="sr-only">Next Item</span>',
      '<span class="sr-only">Previous Item</span>'
    ],
    autoHeight: true
  },
  Columned: {
    loop: false,
    margin: 20,
    nav: false,
    dots: true,
    items: 1,
    smartSpeed: 1000,
    navText: [
      '<span class="sr-only">Next Item</span>',
      '<span class="sr-only">Previous Item</span>'
    ],
    autoHeight: true
  }
}

function getBreakpoint(breakpoint) {
  let property = `--breakpoint-${breakpoint}`
  return parseInt(getComputedStyle(document.body).getPropertyValue(property))
}

function getCarouselArgs(element) {
  if (element.hasClass('carousel--slider')) {
    return CAROUSEL_ARGS.Slider
  } else if (element.hasClass('carousel--cards')) {
    return CAROUSEL_ARGS.Cards
  } else if (element.hasClass('carousel--testimonials')) {
    return CAROUSEL_ARGS.Testimonial
  } else if (element.hasClass('carousel--column')) {
    return CAROUSEL_ARGS.Columned
  }
}

jQuery(($) => {
  $('.owl-carousel').each(function () {
    let $carousel = $(this)
    let args = getCarouselArgs($carousel)
    let navContainer = `.carousel__nav-container[data-parent="#${$carousel.attr(
      'id'
    )}"]`
    args.dotsContainer = `${navContainer} .dots`
    if ($carousel.data('media-down')) {
      $(window).on('load resize', () => {
        let breakpoint = $carousel.data('media-down')
        if (window.innerWidth <= getBreakpoint(breakpoint)) {
          $carousel.owlCarousel(args)
        } else {
          $carousel.trigger('destroy.owl.carousel')
        }
      })
    } else {
      $carousel.owlCarousel(args)
    }
    $(navContainer).on('click', 'button', (event) => {
      let action = event.currentTarget.classList.contains('owl-next')
        ? 'next'
        : 'prev'
      $carousel.trigger(`${action}.owl.carousel`)
    })
  })
})
