jQuery(($) => {
  let loadMoreButton = $('button.btn--more')
  let loadMoreWrapper = $('#practiceAreas')
  if (typeof loadMoreButton != 'undefined') {
    //Button Selection runs AJAX Request
    loadMoreButton.each((index, element) => {
      $(element).on('click', () => {
        let pageOffset = loadMoreWrapper.children().length
        console.log(pageOffset)
        runAjax(pageOffset)
      })
    })
    //AJAX Request
    function runAjax(pageOffset) {
      $.ajax({
        method: 'POST',
        url: TG.ajaxUrl,
        data: {
          action: 'load_more',
          offset: pageOffset,
          security: TG.nonce
        },
        success: (response) => {
          if (response.success == true) {
            loadMoreWrapper.append(response.data.content)
            if (response.data.count < pageOffset) {
              loadMoreButton.css({ opacity: 0, zIndex: -100 })
            }
          } else {
            console.log('AJAX repsonse error')
          }
        }
      })
    }
  }
})
