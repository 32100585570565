import $ from 'jquery'
import { EventBuilder } from './_jquery.ga-lib'
//Add your Google Analytics Events below
//ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);

jQuery(($) => {
  //const debug = !!TG.gaDebug
  const debug = false
  let eventBuilder = new EventBuilder(debug)
  //CATEGORY: Contact
  eventBuilder.bind('a[href^="tel:"]', {})
  eventBuilder.bind('a[href^="mailto:"]', {})

  eventBuilder.bind('address a', {
    action: 'Click',
    category: 'Engagement',
    label: (element) => {
      let value = $(element).text()
      return `Address Clicked | ${value}`
    }
  })
  $('a[href^="tel:"]').on('click', () => {
    fbq('track', 'Contact')
  })
  //CATEGORY: Forms send both Facebook and GA
  $(document).on('gform_confirmation_loaded', (e, formId) => {
    if (formId == 1) {
      ga('send', 'event', 'Contact', 'Submit', 'Form Submitted | Contact Us')
      fbq('track', 'Lead')
    } else {
      ga('send', 'event', 'Contact', 'Submit', 'Form Submitted | ')
      fbq('track', 'Lead')
    }
  })
})
