jQuery(($) => {
  let termFilterButton = $('.term--filter button.term--filter--item')
  let termFilterWrapper = $('#faqAccordian')
  let termFilterSelector = document.querySelector('#termFilterDropdown')
  if (typeof termFilterSelector != 'undefined' && termFilterSelector != null) {
    //Dropdown Selection runs AJAX Request
    termFilterSelector.addEventListener('change', (event) => {
      runAjax(event.target.value)
    })
    //Button Selection runs AJAX Request
    termFilterButton.each((index, element) => {
      $(element).on('click', () => {
        $(element).addClass('active')
        $(element).siblings().removeClass('active')
        let filterTermID = $(element).attr('data-value')
        runAjax(filterTermID)
      })
    })
    //AJAX Request
    function runAjax(filterTermID) {
      $.ajax({
        method: 'POST',
        url: TG.ajaxUrl,
        data: {
          action: 'accordion_term_filter',
          termID: filterTermID,
          security: TG.nonce
        },
        success: (response) => {
          if (response.success == true) {
            termFilterWrapper.html(response.data)
          } else {
            console.log('AJAX repsonse error')
          }
        }
      })
    }
  }
})
