import 'bootstrap/js/src/index'
import './_events'
import './_navigation'
import './_accordianFilter'
import './_categoryFilter'
import './_resultsFilter'
import './_loadMore'
import './_carousels'

jQuery(($) => {
  $('a.btn__scroll').on('click', (event) => {
    if (event.target.hash !== '') {
      event.preventDefault()
      let hash = event.target.hash
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top
        },
        2000
      )
    }
  })
})
