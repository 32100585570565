/*
 * This is a Google Analytics Plugin developed
 * by Timmermann Group to help with sending
 * Google Events.
 */

import $ from 'jquery'
class Event {
  constructor(
    category,
    action,
    label,
    beforeLabel = '',
    afterLabel = '',
    value = 0,
    debug = false,
    trigger = null
  ) {
    this.category = category
    this.action = action
    this.trigger = trigger || this.action.toLowerCase()
    this.label = this.trim(label)
    this.debug = debug
    this.value = value || 0
    this.beforeLabel = beforeLabel
    this.afterLabel = afterLabel
    this.element = null
    this.name = null
  }

  trim(string) {
    return string.replace(/\s+/g, ' ').trim()
  }

  send() {
    if (this.debug == true) {
      console.log(this)
    } else {
      if (window.hasOwnProperty('ga')) {
        ga(
          'send',
          'event',
          this.category,
          this.action,
          this.beforeLabel + this.label + this.afterLabel,
          this.value
        )
      } else {
        console.error('Unable to send event; no ga loaded')
        console.error(this)
      }
    }
  }
}

class FormEnumerator {
  constructor() {
    let forms = {}

    $('.gform_wrapper form').each(function () {
      let title = $(this)
        .find('.ga-form')
        .first()
        .find('input[type=hidden]')
        .first()
        .val()
      let id = $(this).attr('id').split('_')[1]
      forms[id] = title
    })

    this.forms = forms
    this.formEvents = {}

    this.watchAjaxForms()
  }

  watchAjaxForms() {
    let self = this
    $(document).on('gform_confirmation_loaded', (e, formId) => {
      let event = self.formEvents[formId]
      if (event) {
        event.send()
      }
    })
  }

  bindAjaxEvent(element, event) {
    let id = $(element).attr('id').split('_')[1]
    this.formEvents[id] = event
  }
}

export class EventBuilder {
  constructor(debug = false) {
    this.debug = debug

    if (debug === false) {
      if (!window.hasOwnProperty('ga')) {
        console.warn(
          'Debug is disabled but ga not found. Events will not send.'
        )
      }
    }

    this.formEnumerator = new FormEnumerator()
  }

  /**
   *
   * @param {string} selector
   * @param {object} options
   */
  bind(selector, options) {
    let self = this

    let $selector
    let selectorDescription
    let eventName
    if (options && options.name) {
      eventName = options.name
    }

    if (selector instanceof jQuery) {
      $selector = selector
      selectorDescription =
        eventName || selector.selector || '[Unknown jQuery selector*]'
    } else {
      $selector = $(selector)
      selectorDescription = eventName || selector
    }

    let excludedElements = []
    if (options && options.excludes) {
      excludedElements = options.excludes
    }

    let events = []

    $selector.each(function () {
      let item = this
      if (
        excludedElements.length > 0 &&
        excludedElements.filter((e) => {
          return $(item).is(e)
        }).length > 0
      ) {
        if (this.debug == true) {
          console.log(
            `(Builder) Exlusion found during '${selectorDescription}' binding, skipped.`
          )
        }
        return
      }

      let event = self.generate(this, options)
      events.push(event)
      if (event.trigger == 'gform_confirmation_loaded') {
        self.formEnumerator.bindAjaxEvent(this, event)
      } else {
        $(this).on(event.trigger.toLowerCase(), () => {
          event.send()
        })
      }
    })

    if (this.debug == true) {
      console.log(
        `(Bind) Added '${selectorDescription};' ${events.length} match(es)`
      )
    }

    return events
  }

  dynamicBind(selector, dynamicTrigger, options) {
    let self = this

    if (selector instanceof jQuery) {
      selector = selector.selector
    } else {
      selector = selector
    }

    let eventName
    if (options && options.name) {
      eventName = options.name
    } else {
      eventName = selector
    }

    if (this.debug == true) {
      console.log(`(Dynamic Bind) '${eventName}' waiting for ${dynamicTrigger}`)
    }

    $('body').on(dynamicTrigger.toLowerCase(), selector, function (e) {
      /* Note how we override any provided trigger with the passed value*/
      options.trigger = dynamicTrigger.toLowerCase()
      let generatedTrigger = self.getTrigger($(this), options)
      if (generatedTrigger == 'gform_confirmation_loaded') {
        console.warn(
          'AJAX Forms Cannot be Dynamically Binded Properly - This may result in missed events.'
        )
      }
      self.generate(this, options).send()
    })
  }

  generate(selector, options) {
    let element
    if (selector instanceof jQuery) {
      element = selector
    } else {
      element = $(selector)
    }

    let beforeLabel = this.getBeforeLabel(element, options)
    let label = this.getLabel(element, options)
    let afterLabel = this.getAfterLabel(element, options)
    let category = this.getCategory(element, options)
    let action = this.getAction(element, options)
    let trigger = this.getTrigger(element, options)
    let value = this.getValue(element, options)

    let event = new Event(
      category,
      action,
      label,
      beforeLabel,
      afterLabel,
      value,
      this.debug,
      trigger
    )
    // Add Element
    event.element = element

    // Name the event, if possible.
    if (options && options.name) {
      event.name = options.name
    }
    return event
  }

  getBeforeLabel(element, args) {
    if (element.data('before-label')) {
      return element.data('before-label')
    } else if (args && args.beforeLabel) {
      if (typeof args.beforeLabel == 'function') {
        return args.beforeLabel(element) || ''
      } else {
        return args.beforeLabel || ''
      }
    } else if (element.is('form')) {
      return 'Form Submitted | '
    } else if (element.is('a[href^="tel:"]')) {
      return 'Phone Number Clicked | '
    } else if (element.is('a[href^="mailto:"]')) {
      return 'Email Address Clicked | '
    } else if (element.is('.ga-address')) {
      return 'Address Clicked | '
    } else {
      return ''
    }
  }

  getLabel(element, args) {
    if (element.data('label')) {
      return element.data('label')
    } else if (args && args.label) {
      if (typeof args.label == 'function') {
        return args.label(element) || ''
      } else {
        return args.label || ''
      }
    } else if (element.is('form')) {
      if (element.find('.ga-form input[type="hidden"]').val()) {
        return element.find('.ga-form input[type="hidden"]').val()
      } else {
        return 'Form'
      }
    } else {
      return element.text()
    }
  }

  getAfterLabel(element, args) {
    if (element.data('after-label')) {
      return element.data('after-label')
    } else if (args && args.afterLabel) {
      if (typeof args.afterLabel == 'function') {
        return args.afterLabel(element) || ''
      } else {
        return args.afterLabel || ''
      }
    } else if (element.hasClass('ga-download')) {
      //.. else if the element is a mailto link..
      return ' | Downloaded'
    } else {
      return ''
    }
  }

  getCategory(element, args) {
    if (element.data('category')) {
      return element.data('category')
    } else if (args && args.category) {
      return args.category
    } else if (
      element.is('a[href^="tel:"]') ||
      element.is('a[href^="mailto:"]') ||
      element.is('form')
    ) {
      return 'Contact'
    } else if (element.hasClass('ga-download')) {
      return 'Downloads'
    } else {
      return 'Engagement'
    }
  }

  getAction(element, args) {
    if (element.data('action')) {
      return element.data('action')
    } else if (args && args.action) {
      return args.action
    } else if (element.is('form')) {
      return 'Submit'
    } else if (element.hasClass('ga-download')) {
      return 'Download'
    } else {
      return 'Click'
    }
  }

  begins(str, word) {
    return str.lastIndexOf(word, 0) === 0
  }

  getTrigger(element, args) {
    if (element.data('trigger')) {
      return element.data('trigger')
    } else if (args && args.trigger) {
      return args.trigger
    } else if (!element.is('form')) {
      return this.getAction(element, args)
    }

    if (
      element.attr('target') &&
      this.begins(element.attr('target'), 'gform_ajax')
    ) {
      return 'gform_confirmation_loaded'
    } else {
      return 'submit'
    }
  }

  getValue(element, args) {
    // If we provide an integer value...
    if (element.data('value') && Number.isInteger(element.data('value'))) {
      return element.data('value')
    } else if (args && args.value) {
      if (typeof args.value == 'function') {
        return args.value(element) || ''
      } else {
        return args.value || ''
      }
    } else if (element.is('input')) {
      // Attempt to pull value from an input...
      let untestedValue = element.val()
      if (Number.isInteger(untestedValue)) {
        // And use it if it's an integer.
        return untestedValue
      } else {
        return 0
      }
    }
  }
}
